<template>
    <div>
        <el-row style="padding: 25px">
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="formSearch" class="demo-form-inline">
                    <el-form-item label="名称:">
                        <el-input style="width: 200px" v-model="search" placeholder="请输入分组名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right; margin-right: 0">
                        <el-button style="width: 100px" type="primary" @click="open('add', 0)">
                            添加分组
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 100%">
                <!-- default-expand-all 全部展开 -->
                <el-table :data="accountManageDataList" style="width: 100%; margin-bottom: 20px" row-key="id" border
                    :header-cell-style="{ background: '#F2F2F2' }">
                    <el-table-column prop="name" label="名字" />
                    <el-table-column prop="provinces" label="地区" align='center'>
                        <template #default="scope">
                            {{ scope.row.provinces }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" sortable align='center' />
                    <el-table-column prop="status" label="操作" align='center' width="200px">
                        <template #default="scope">
                            <el-button type="text" size="small" @click="open('add', scope.row.id)">新增子集</el-button>
                            <el-button type="text" size="small" @click="open('edit', scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- <div style="text-align: right; margin-top: 25px" v-if="page.total != 0">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                    </el-pagination>
                </div> -->
            </div>
        </el-row>

        <!-- 添加/编辑分组弹窗 -->
        <el-dialog v-model="dialogFormAddEditVisible" :title="type == 'add' ? '添加分组' : '编辑分组'" width="450px" center
            :before-close="handleClose" :close-on-click-modal="false">
            <div style="margin-right: 10px;">
                <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm"
                    :size="formSize" status-icon>
                    <el-form-item label="分组名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入分组名称" />
                    </el-form-item>
                    <el-form-item label="省&emsp;&emsp;份" v-if="provinceShow">
                        <el-select style="width: 290px;" v-model="ruleForm.valueProvince" multiple placeholder="请选择省份">
                            <el-option-group v-for="group in optionsProvince" :key="group.name" :label="group.name">
                                <el-option v-for="item in group.childer" :key="item.name" :label="item.name"
                                    :value="item.name" />
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="分组说明">
                        <el-input v-model="ruleForm.textarea2" :autosize="{ minRows: 4 }" type="textarea"
                            placeholder="请输入说明" />
                    </el-form-item> -->
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button v-if="type == 'add'" type="primary" @click="add">确定</el-button>
                    <el-button v-else type="primary" @click="edit">保存</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { seller_role, seller_roleAdd, seller_roleDelete, seller_roleEdit } from '../../common/js/sellManage'
export default {
    // 权限管理
    name: 'sellManageAuthority',
    // 组件参数 接收来自父组件的数据
    props: {},
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            // 查询
            search: '',
            // 列表数据
            accountManageDataList: [],
            // 是否显示添加/编辑分组弹窗
            dialogFormAddEditVisible: false,
            // 添加/编辑类型
            type: '',
            // 子集ID
            id: '',
            // 父级id
            pid: '',
            // 添加编辑分组
            ruleForm: {
                name: '',
                valueProvince: [],
                // textarea2: '',
            },
            // 分组规则
            rules: {
                name: [
                    { required: true, message: '请输入分组名称', trigger: 'blur' }
                ],
            },
            provinceShow: null,
            // 省级分类  写死
            optionsProvince: [
                {
                    name: '华南地区',
                    childer: [
                        {
                            name: '深圳'
                        },
                        {
                            name: '广州'
                        },
                        {
                            name: '广东'
                        },
                        {
                            name: '广西'
                        },
                        {
                            name: '海南'
                        },
                        {
                            name: '河南'
                        },
                        {
                            name: '湖北'
                        },
                        {
                            name: '湖南'
                        },
                    ]
                },
                {
                    name: '华北地区',
                    childer: [
                        {
                            name: '北京'
                        },
                        {
                            name: '天津'
                        },
                        {
                            name: '山西'
                        },
                        {
                            name: '河北'
                        },
                        {
                            name: '山东'
                        },
                    ],
                },
                {
                    name: '华东地区',
                    childer: [
                        {
                            name: '上海'
                        },
                        {
                            name: '江苏'
                        },
                        {
                            name: '浙江'
                        },
                        {
                            name: '安徽'
                        },
                        {
                            name: '江西'
                        },
                        {
                            name: '福建'
                        },
                    ]
                },
                {
                    name: '西南地区',
                    childer: [
                        {
                            name: '重庆'
                        },
                        {
                            name: '云南'
                        },
                        {
                            name: '四川'
                        },
                        {
                            name: '贵州'
                        },
                    ]
                },
                {
                    name: '东北地区',
                    childer: [
                        {
                            name: '黑龙江'
                        },
                        {
                            name: '吉林'
                        },
                        {
                            name: '辽宁'
                        },
                    ]
                },
                {
                    name: '西北地区',
                    childer: [
                        {
                            name: '陕西'
                        },
                        {
                            name: '甘肃'
                        },
                        {
                            name: '宁夏'
                        },
                        {
                            name: '新疆'
                        },
                    ]
                }
            ]
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
        dialogFormAddEditVisible() {
            if (this.dialogFormAddEditVisible == false) {
                this.ruleForm.name = ''
                this.ruleForm.valueProvince = []
                this.id = ''
                this.pid = ''
                this.type = ''
            }
        }
    },
    // 方法集合
    methods: {
        // 初始化
        init() {
            seller_role({ search: this.search }).then(res => {
                let { code, result } = res
                if (code == 200) {
                    this.accountManageDataList = result
                }
            })
        },
        // 打开编辑面板
        open(e, id) {
            if (id == 0) {
                this.provinceShow = true
            } else {
                this.provinceShow = false
            }
            if (e == 'add') {
                this.type = e
                this.id = id
                this.dialogFormAddEditVisible = true
            } else {
                if (id.pid == 0) {
                    this.provinceShow = true
                    let arr = null
                    if (id.provinces != '') {
                        arr = id.provinces.split("、");
                    }
                    this.ruleForm.valueProvince = arr
                } else {
                    this.provinceShow = false
                }
                this.type = e
                this.id = id.id
                this.pid = id.pid
                this.ruleForm.name = id.name
                this.dialogFormAddEditVisible = true
            }
        },
        // 新增分组
        add() {
            let dt = {
                name: this.ruleForm.name,
                pid: this.id,
                provinces: this.ruleForm.valueProvince,
            }
            seller_roleAdd(dt).then(res => {
                let { code, msg } = res
                if (code == 200) {
                    this.dialogFormAddEditVisible = false
                    this.$message.success(msg)
                    this.init()
                }
            })
        },
        // 编辑分组
        edit() {
            let dt = {
                name: this.ruleForm.name,
                pid: this.pid,
                id: this.id,
                provinces: this.ruleForm.valueProvince,
            }
            seller_roleEdit(dt).then(res => {
                let { code, msg } = res
                if (code == 200) {
                    this.dialogFormAddEditVisible = false
                    this.$message.success(msg)
                    this.init()
                }
            })
        },
        // 删除分组
        del(item) {
            this.$confirm('确认删除当前分组吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    seller_roleDelete({ id: item.id }).then(res => {
                        let { code, msg } = res
                        if (code == 200) {
                            this.$message.success(msg)
                            this.init()
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理查询
        handleSearch() {
            this.init();
        },
        // 下标序号
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.init()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() { },
    // 这里存放数据
    beforeCreate() { },
    // 生命周期 - 挂载之前 
    beforeMount() { },
    // 生命周期 - 更新之前 
    beforeUpdate() { },
    // 生命周期 - 更新之后 
    updated() { },
    // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { }
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
::v-deep .el-dialog__body {
    padding-bottom: 0;
}
</style>